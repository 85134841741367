import { FieldRow, Switch } from "@app/design-system";
import { avlDataLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { AVLPositionsLayerState } from "./AVLPositions";

const AVLPositionsSettingsComponent = () => {
  const { getLayerState, setLayerState } = useActiveLayersContext();

  const label = "Show labels";
  const state = getLayerState<AVLPositionsLayerState>(avlDataLayer.id);

  return (
    <FieldRow label={label}>
      <Switch
        aria-label={label}
        defaultSelected
        size="sm"
        onChange={(showLabels) => {
          setLayerState<AVLPositionsLayerState>({
            id: avlDataLayer.id,
            source: "layers-drawer",
            state: { showLabels },
          });
        }}
        {...(state && { isSelected: state.showLabels })}
      />
    </FieldRow>
  );
};

export default AVLPositionsSettingsComponent;
